import type * as React from "react";
import Link from "next/link";
import SimpleBlockContent from "./SimpleBlockContent";
import {
	FundaLogoIcon,
	FacebookIcon,
	LinkedinIcon,
	InstagramIcon,
	BaronTextLogoIcon,
	VastgoedproLogoIcon,
	ParariusLogoIcon,
} from "icons/components";
import { BaronLogoFlag } from "./baron-logo-flag";
import type {
	Page,
	SanityKeyed,
	Service,
	SiteConfig,
} from "../types/generated/schema";
import { resolveLink } from "../utils/link";

type Props = Omit<SiteConfig, "footerNavigation"> & {
	footerNavigation?: Array<SanityKeyed<Page | Service>>;
};

export default function Footer({
	footerNavigation,
	footerText,
	footerSocials,
	copyrightText,
	siteContactInfo,
}: Props): JSX.Element {
	return (
		<footer className="relative z-10 pb-6 font-bold text-white uppercase bg-black">
			<div className="flex justify-center w-full transform -translate-y-8">
				<BaronLogoFlag title="Uw Baron" variant="small" />
				<BaronTextLogoIcon className="w-full h-10 max-w-sm mt-28 md:h-16" />
			</div>
			<div className="flex justify-center w-full" />
			<div className="px-16 mx-auto py-14 max-w-7xl md:px-10">
				<div className="grid gap-6 md:grid-cols-4">
					<div className="col-span-1 lg:px-10 xl:px-16">
						<FooterColumnHeader>Partners</FooterColumnHeader>
						<div className="flex flex-col space-y-2">
							<a
								href="https://www.funda.nl/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FundaLogoIcon width={100} height={40} />
							</a>
							<a
								href="https://www.vastgoedpro.nl/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<VastgoedproLogoIcon width={140} height={40} />
							</a>
							<a
								href="https://www.pararius.nl/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<ParariusLogoIcon width={120} height={40} />
							</a>
						</div>
					</div>
					<nav className="col-span-1 lg:px-10 xl:px-16">
						<FooterColumnHeader>Diensten</FooterColumnHeader>
						<ul className="space-y-1">
							{footerNavigation?.map((item) => {
								return (
									<li key={item._id}>
										<Link href={resolveLink(item)}>{item.title}</Link>
									</li>
								);
							})}
						</ul>
					</nav>
					<nav className="col-span-1 lg:px-10 xl:px-14">
						<FooterColumnHeader>Contact</FooterColumnHeader>
						<div itemScope itemType="https://schema.org/Organization">
							<span itemProp="name">Uw Baron</span>
							<div
								className="flex flex-col pt-1 space-y-1"
								itemProp="address"
								itemScope
								itemType="https://schema.org/PostalAddress"
							>
								{siteContactInfo.phoneNumber && (
									<span itemProp="telephone">
										<a
											href={`tel:+31${siteContactInfo.phoneNumber.substring(
												1,
											)}`}
										>
											{siteContactInfo.phoneNumber}
										</a>
									</span>
								)}
								{siteContactInfo.email && (
									<span itemProp="email">
										<a href={`mailto:${siteContactInfo.email}`}>
											{siteContactInfo.email}
										</a>
									</span>
								)}
								{siteContactInfo.address && (
									<a
										href="https://goo.gl/maps/oBeKdih8HCuNqKy29"
										target="_blank"
										rel="noopener noreferrer"
									>
										<span itemProp="streetAddress">
											{siteContactInfo.address}
										</span>
									</a>
								)}
								{siteContactInfo.zipcode && siteContactInfo.city && (
									<div className="flex flex-row space-x-2">
										<a
											href="https://goo.gl/maps/oBeKdih8HCuNqKy29"
											target="_blank"
											rel="noopener noreferrer"
										>
											<span itemProp="postalCode">
												{siteContactInfo.zipcode}
											</span>{" "}
											<span itemProp="addressLocality">
												{siteContactInfo.city}
											</span>
										</a>
									</div>
								)}
							</div>
						</div>
					</nav>
					<nav className="col-span-1 lg:px-10 xl:px-16">
						<FooterColumnHeader>Social media</FooterColumnHeader>
						<div className="flex flex-row space-x-2">
							{footerSocials?.map((item, i) => {
								switch (item.platform) {
									case "facebook":
										return (
											<SocialLink key={i} link={item.platformUrl}>
												<FacebookIcon
													aria-label="Facebook Logo"
													width={28}
													height={28}
												/>
											</SocialLink>
										);
									case "instagram":
										return (
											<SocialLink key={i} link={item.platformUrl}>
												<InstagramIcon
													aria-label="Instagram Logo"
													width={28}
													height={28}
												/>
											</SocialLink>
										);

									case "linkedin":
										return (
											<SocialLink key={i} link={item.platformUrl}>
												<LinkedinIcon
													aria-label="LinkedIn Logo"
													width={28}
													height={28}
												/>
											</SocialLink>
										);
								}
							})}
						</div>
					</nav>
				</div>
			</div>
			<div className="text-center text-primary">
				<SimpleBlockContent value={footerText} />
			</div>
			<div className="mt-4 text-center text-primary">
				<SimpleBlockContent value={copyrightText} />
			</div>
		</footer>
	);
}

const FooterColumnHeader: React.FC<React.PropsWithChildren<unknown>> = ({
	children,
}) => <h4 className="mb-4 font-extrabold text-primary">{children}</h4>;

const SocialLink: React.FC<React.PropsWithChildren<{ link: string }>> = ({
	link,
	children,
}): JSX.Element => {
	return (
		<a href={link} target="_blank" rel="noopener noreferrer">
			{children}
		</a>
	);
};

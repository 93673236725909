import React from "react";
import Head from "next/head";
import PropTypes from "prop-types";
import { Header } from "./header";
import Footer from "./footer";
import { Meta } from "./meta";

function Layout(props) {
	const { config, tags, children } = props;

	if (!config) {
		console.error("Missing config");

		return <div>Missing config</div>;
	}

	const {
		title,
		mainNavigation,
		footerNavigation,
		footerText,
		copyrightText,
		siteContactInfo,
		footerSocials,
		logo,
		url,
	} = config;
	const logoUrl = logo?.asset?.url;

	return (
		<>
			<Head>
				<meta
					name="viewport"
					content="initial-scale=1.0, width=device-width, viewport-fit=cover"
				/>
			</Head>

			<Meta tags={tags} />

			<Header
				title={title}
				mainNavigation={mainNavigation}
				siteContactInfo={siteContactInfo}
				logo={logo}
			/>
			<div className="content">{children}</div>
			<Footer
				footerNavigation={footerNavigation}
				footerText={footerText}
				footerSocials={footerSocials}
				copyrightText={copyrightText}
				siteContactInfo={siteContactInfo}
			/>
			{logoUrl && url && <LogoJsonLd url={url} logo={logoUrl} />}
		</>
	);
}

Layout.propTypes = {
	children: PropTypes.arrayOf(PropTypes.node) || PropTypes.node,
	config: PropTypes.shape({
		title: PropTypes.string,
		mainNavigation: PropTypes.object,
		footerNavigation: PropTypes.arrayOf(PropTypes.object),
		footerText: PropTypes.arrayOf(PropTypes.object),
		logo: PropTypes.shape({
			asset: PropTypes.shape({
				url: PropTypes.string,
			}),
		}),
		url: PropTypes.string,
	}),
	tags: PropTypes.object,
};

export default Layout;

const markup = (jsonld) => ({ __html: jsonld });

function LogoJsonLd({ keyOverride, url, logo }) {
	const jslonld = `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "${url}",
    "logo": "${logo}"
  }`;

	return (
		<Head>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={markup(jslonld)}
				key={`jsonld-logo${keyOverride ? `-${keyOverride}` : ""}`}
			/>
		</Head>
	);
}

import * as React from "react";
import Link from "next/link";
import { BaronShortIcon } from "icons/components";
import sx from "./baron-logo-flag.module.css";
import clsx from "clsx";

type Props = {
	title: string;
	variant?: "small" | "large";
};

export function BaronLogoFlag({
	title,
	variant = "large",
}: Props): JSX.Element {
	return (
		<Link
			href="/"
			title={title}
			className={clsx(sx.logoFlag, {
				[sx.logoFlagLarge]: variant === "large",
				[sx.logoFlagSmall]: variant === "small",
			})}
		>
			<BaronShortIcon
				width={variant === "large" ? 80 : 40}
				height={variant === "large" ? 80 : 40}
			/>
		</Link>
	);
}

export function BaronLogoFlagMap(): JSX.Element {
	return (
		<div className={clsx(sx.logoFlag, sx.logoFlagMap)}>
			<BaronShortIcon width={24} height={24} />
		</div>
	);
}

import type { Page, Service } from "../types/generated/schema";

export function resolveLink(data: Page | Service): string {
	switch (data._type) {
		case "page":
			if (data.slug.current === "/") return "/";

			return `/${data.slug.current}`;
		case "service":
			return `/diensten/${data.slug.current}`;
	}
}

type BlockContentLink = {
	type: string;
	slug: string;
};

export function resolveBlockContentLink(data: BlockContentLink): string {
	switch (data.type) {
		case "page":
			if (data.slug === "/") return "/";

			return `/${data.slug}`;
		case "service":
			return `/diensten/${data.slug}`;
	}
}

export type PossibleRouteTypes = Page | Service;

export type DereferenceRoutes<T> = Omit<T, "route"> & {
	route?: PossibleRouteTypes;
};

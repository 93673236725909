import Link from "next/link";
import EmbedHTML from "./EmbedHTML";
import Figure from "./Figure";
import { resolveBlockContentLink } from "@/utils/link";

const serializers = {
	types: {
		embedHTML: EmbedHTML,
		figure: Figure,
	},
	marks: {
		// eslint-disable-next-line react/display-name
		internalLink: ({ value, children }) => {
			const href = resolveBlockContentLink(value);

			if (!href) return null;

			return (
				<a href={href} className="font-bold hover:text-primaryDark">
					{children}
				</a>
			);
		},
		// eslint-disable-next-line react/display-name
		link: ({ value, children }) => {
			const { blank, href } = value;

			return blank ? (
				<a href={href} target="_blank" rel="noopener noreferrer">
					{children}
				</a>
			) : (
				<a href={href}>{children}</a>
			);
		},
	},
};

export default serializers;

import React from "react";
import PropTypes from "prop-types";
import { PortableText } from "@portabletext/react";
import client from "../../client";
import serializers from "./serializers";

const { projectId, dataset } = client.config();

function SimpleBlockContent(props) {
	const { value: blocks } = props;

	if (!blocks) {
		console.error("Missing blocks");

		return null;
	}

	return (
		<PortableText
			className="space-y-4 prose-sm prose max-w-none"
			value={blocks}
			components={serializers}
			projectId={projectId}
			dataset={dataset}
		/>
	);
}

SimpleBlockContent.propTypes = {
	value: PropTypes.arrayOf(PropTypes.object),
};

export default SimpleBlockContent;
